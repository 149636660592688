
.mac-scrollbar::-webkit-scrollbar {
  background-color: #172b4d;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
.mac-scrollbar::-webkit-scrollbar-track {
  background-color: #172b4d;
}

/* scrollbar itself */
.mac-scrollbar::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #172b4d;
}

/* set button(top and bottom of the scrollbar) */
.mac-scrollbar::-webkit-scrollbar-button {
  display:none;
}

@media (min-width: 768px){
  .product-modal {
    max-width: 650px;
  }
}

@media (min-width: 992px){
  .product-modal {
    max-width: 800px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.file-buttons {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
}